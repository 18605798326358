<template>
  <CInputGroup @click="$emit('touched')">
    <slot name="label"></slot>

    <CInput>
      <template #input>
        <div class="border-dashed sign-tablet-wrapper mb-4 mb-lg-0">
          <div class="d-flex">
            <div>
              <CIcon class="sign-tablet-wrapper__icon" :name="icon" />
            </div>
            <div class="picture-holder">
              <div class="picture-holder__drawing-area">

                <img
                  v-if="pictureUrl"
                  :src="pictureUrl"
                  class="picture"
                  alt="picture"
                />

                <VueSignaturePad
                  v-else
                  :key="signaturePadRenderIterationKey"
                  ref="signaturePad"
                  class="drawing-pad"
                  height="150px"
                />

              </div>
              <div class="sign-line"></div>
              <div class="toolbox">
<!--                <CButton class="modal__btn clear mr-3" @click="uploadFromFile" color="link">-->
<!--                  {{ uploadActionText }}-->
<!--                </CButton>-->

                <input
                  ref="uploadPicture"
                  type="file"
                  accept=".jpg,.png"
                  class="d-none"
                  @change="handleChangeFile"
                />

                <CButton color="primary" variant="outline" size="sm" @click="onClear">
                  Clear
                </CButton>

                <CButton color="primary" variant="outline" size="sm" @click="uploadFromFile">
                  {{ uploadActionText }}
                </CButton>
<!--                <CButton class="modal__btn clear mr-3"-->
<!--                         @click="openModal"-->
<!--                         color="link">-->
<!--                  {{ drawActionText }}-->
<!--                </CButton>-->

<!--                <CButton-->
<!--                  v-if="userId === $store.getters['user'].id"-->
<!--                  class="modal__btn clear d-none"-->
<!--                  color="link"-->
<!--                  @click="openModal"-->
<!--                >-->
<!--                  {{ $t('Change') }}-->
<!--                </CButton>-->
              </div>
            </div>
          </div>
        </div>
      </template>
    </CInput>

    <CModal
      :visible="showModal"
      @close="closeModal"
      backdrop="static"
      @show="signaturePadRenderIterationKey++"
    >
      <CModalHeader> </CModalHeader>
      <CModalBody>
        <div
          class="border-dashed sign-tablet-wrapper sign-tablet-wrapper__modal"
        >
          <VueSignaturePad
            :key="signaturePadRenderIterationKey"
            ref="signaturePad"
            class="drawing-pad"
            height="8rem"
          />

          <img
            src="@/assets/document-sign.svg"
            class="mb-2"
            alt="document sign"
          />
          <div class="sign-line"></div>
        </div>
      </CModalBody>
      <CModalFooter class="flex align-items-center justify-content-center">
        <submit-btn
          class="modal__btn"
          color="primary"
          :loading="submitLoading"
          @click="handleSubmit"
        >
          {{ $t('Save') }}
        </submit-btn>
        <CButton class="modal__btn clear" color="link" @click="clearSignature">
          {{ $t('Clear') }}
        </CButton>
      </CModalFooter>
    </CModal>
  </CInputGroup>
</template>

<script>
import { VueSignaturePad } from 'vue-signature-pad'
export default {
  name: 'DrawingUploader',
  components: { VueSignaturePad },
  inject: ['toast'],
  props: {
    submit: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    uploader: Function,
    userId: [Number, String],
    pictureUrl: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
  },
emits: ['uploaded'],
  data: () => ({
    showModal: false,
    submitLoading: false,
    signaturePadRenderIterationKey: 1,
    signatureUploading: true,
  }),
  computed: {
    drawActionText(){
      return this.type === 'signature'
        ? 'Draw signature'
        : 'Draw initials'
    },
    uploadActionText(){
      return 'Upload from file'
    },
  },
  methods: {
    onClear() {
      if (this.pictureUrl) {
        this.$emit('clear', this.type)
      } else {
        this.clearSignature();
      }
    },
    uploadFromFile(){
      this.$refs.uploadPicture.click()
    },
    resizeImage (settings, context) {
      let file = settings.file
      let maxWidth = settings.maxWidth
      let maxHeight = settings.maxHeight
      let reader = new FileReader()
      let image = new Image()
      let canvas = document.createElement('canvas')
      let dataURItoBlob = function (dataURI) {
        let bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1]);
        let mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let max = bytes.length
        let ia = new Uint8Array(max)
        for (var i = 0; i < max; i++){
          ia[i] = bytes.charCodeAt(i);
        }
        return new Blob([ia], { type: mime })
      };
      let resize = function () {
        let width = image.width
        let height = image.height
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height
            height = maxHeight
          }
        }
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)
        let dataUrl = canvas.toDataURL('image/jpeg')
        return dataURItoBlob(dataUrl)
      }

      return new Promise(function (resolve, reject) {
        if (!file.type.match(/image.*/)) {
          context.toast('success', 'The file is not an image')
          reject(new Error("Not an image"))
          return
        }
        reader.onload = function (readerEvent) {
          image.onload = function () { return resolve(resize()); }
          image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file);
      })
    },
    async handleChangeFile(e) {
      const file = e.target.files[0]
      let image = await this.resizeImage({
        file, maxHeight: 200, maxWidth: 200
      }, this)
      const formData = new FormData()
      formData.append('file', image)
      this.signatureUploading = true
      this.uploader(formData)
        .then((res) => {
          this.$emit('uploaded', res)
          this.url = res.data.signature
          this.closeModal()
        })
        .finally(() => {
          this.signatureUploading = false
          this.$refs.uploadPicture.value = null
        })
    },
    openModal() {
      if (this.$store.getters['user'].id !== this.userId) return
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    async handleSubmit() {
      if (this.pictureUrl) return false;

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      const formData = new FormData()

      if (!isEmpty) {
        let file = await fetch(data)
          .then((res) => res.blob())
          .then((res) => {
            return new File([res], 'file.png')
          })
        this.submitLoading = true
        formData.append('file', file)
      }

      this.uploader(formData)
        .then((res) => {
          this.$emit('uploaded', res)
          this.url = res.data.signature
          this.closeModal()
        })
        .catch(e => this.toast('success', e))
        .finally(() => {
          this.submitLoading = false
        })
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature()
    },
  },
  watch: {
    submit(val) {
      if (val) this.handleSubmit();
    }
  }
}
</script>

<style lang="scss">
:deep(.modal.modal-static){
  .modal-dialog {
    transform: none !important;

  }
}

.toolbox {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding-top: 16px;
}

.input-group {

  .sign-tablet-wrapper {
    padding: 1rem;
    width: 100%;

    &__icon {
      margin-top: 55px;
      height: 48px !important;
      width: 48px !important;
      fill: #677A89;

      & path {
        fill: #677A89;
      }
    }

    &.border-dashed {

      .picture-holder {
        width: 100%;

        &__drawing-area {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 150px;
        }
      }
      img.picture {
        max-height: 80%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .sign-line {
      margin-left: 0.8rem;
      border-bottom: 1px dashed #677A89;
    }
    &__modal {
      padding-top: 0;
      .drawing-pad {
        position: relative;
        margin-bottom: -4rem;
      }
    }
  }
}
</style>
