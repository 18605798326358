<template>
  <div>
    <CModal :visible="showModal" backdrop="static" @close="() => showModal = false" size="md">
      <CModalBody class="text-center">

        <h4 class="pt-5 pb-3" v-if="config.title">{{ config.title }}</h4>
        <div :class="{'mt-5': !config.title}">
          <template v-if="config.text && Array.isArray(config.text)" >
            <p class="text-center" v-for="(text, textIndex) in config.text" :key="textIndex">{{ text }}</p>
          </template>
          <p class="text-center" v-else >{{ config.text }}</p>
        </div>
      </CModalBody>
      <CModalFooter class="flex align-items-center justify-content-around mb-4">
        <button class="btn btn-outline-secondary" color="link" @click="handleCancel" v-if="!config.confirmOnly">
          {{ config.cancelText }}
        </button>
        <submit-btn class="btn" color="primary" @click="handleConfirm">
          {{ config.confirmText }}
        </submit-btn>
      </CModalFooter>
    </CModal>

  </div>

</template>

<script>
export default {
  name: 'ConfirmationModal',
  data: vm => ({
    showModal: false,
    reject: null,
    resolve: null,
    config: {
      title: vm.$t('Please, confirm action'),
      text: vm.$t('Are you sure you want to proceed?'),
      cancelText: vm.$t('Cancel'),
      confirmText: vm.$t('Confirm'),
      confirmOnly: false
    }
  }),
  methods: {
    /**
     * @param {Object} config
     * @param {string} config.title
     * @param {string} config.text
     * @param {string} config.cancelText
     * @param {string} config.confirmText
     * @param {boolean} config.confirmOnly
     */

    confirm(config = {}){
      this.config = { ...this.config, ...config }
      this.showModal = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleCancel(){
      this.showModal = false
      this.resolve(false)
    },
    handleConfirm(){
      this.showModal = false
      this.resolve(true)
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.modal){
  &.modal-static{
    .modal-dialog {
      transform: none !important;
    }
  }
  .modal-content {
    border-radius: 12px;
  }
  .modal-footer{
    button {
      border-radius: 8px;
      min-width: 200px;
    }
  }
}
</style>
