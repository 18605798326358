<template>
  <div>
    <CFormLabel class="mt-3">
      {{ $t('Email') }}
      <small v-if="emailCandidate" class="text-danger ml-2">{{
        $t('not verified')
      }}</small>
      <small v-else class="text-success ml-2">{{ $t('verified') }}</small>
    </CFormLabel>

    <div class="row">
      <div class="col">
        <CFormInput
          v-model="email"
          type="email"
          :placeholder="$t('Email')"
          :invalid="validator.has('email')"
          class="is-text-if-disabled"
          :disabled="!userCanChangeEmail"
          @keyup.enter="handleSubmit"
          @input="validator.forget('email')"
        />
        <CFormText
          v-if="validator.has('email')"
          :style="`color: ${validator.has('email') && 'red'}`"
          >{{ validator.get('email') }}</CFormText
        >
      </div>
      <div v-if="userCanChangeEmail" class="d-grid send-verification-btn">
        <submit-btn
          class="modal__btn btn-lg"
          color="primary"
          :loading="busy"
          @click="handleSubmit"
        >
          {{ $t('Verify new Email') }}
        </submit-btn>
      </div>
    </div>
  </div>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { parseError } from '@/utils/api'
import permissions from '@/utils/constants/permissions'

export default {
  name: 'CompanyUserProfileChangeEmail',
  inject: ['toast'],
  props: {
    userId: [Number, String],
    currentEmail: String,
    emailCandidate: String,
  },
  data() {
    return {
      validator: validator({ email: [rules.required, rules.email] }),
      email: null,
      busy: false,
    }
  },
  computed: {
    userCanChangeEmail() {
      return (
        parseInt(this.authUser?.id) !== parseInt(this.userId) &&
        this.authUser.can(permissions.ACCOUNT_CONFIGURATION_EDIT)
      )
    },
  },
  created() {
    this.email = this.emailCandidate || this.currentEmail
  },
  methods: {
    handleSubmit() {
      const validationResult = this.validator.validateAll({ email: this.email })
      if (validationResult.hasErrors) return
      this.busy = true

      this.$http.userProfiles
        .changeEmail({ email: this.email, user_id: this.userId })
        .then((res) => {
          this.toast('success', res.data.message)
          this.$emit('request-created', this.email)
        })
        .catch((err) => {
          err = parseError(err)
          if (err.status === 422) {
            this.validator.errors = err.validationMessages
          } else {
            this.toast('error', err.message)
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.send-verification-btn {
  @media (min-width: 575px) {
    flex: 0 0 200px;
    height: 38px;
  }
  @media (max-width: 575px) {
    margin-top: 1rem;
  }
}
</style>
